
import { Options, Vue } from "vue-class-component";
import SongsListItem from "@/components/SongsListItem.vue";

interface SongItem {
  songName: string;
  duration: number;
  timeTillSong: number;
}

@Options({
  components: {
    SongsListItem,
  },
  methods: {
    async update() {
      const streamerId = this.$route.params.streamerId;
      const data = await fetch("/api/queue/" + streamerId);
      const json = await data.json();

      this.timestamp = json.ts;
      this.songs = json.q.q;

      this.currentSong = {
        songName: json.q.currentSong,
        timeTillSong: json.q.duration - json.q.progress,
      };

      this.$forceUpdate();
    },
  },
  async mounted() {
    this.update();
    setInterval(this.update.bind(this), 5000);
  },
})
export default class HomeView extends Vue {
  songs: SongItem[] = [];
  currentSong?: SongItem;
  timestamp = 0;
}
