
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: String,
    time: Number,
    timestamp: Number,
    isCurrent: Boolean,
  },
  methods: {
    update() {
      const timeFromLastUpdate = Date.now() - this.ts;
      this.calculatedTime -= timeFromLastUpdate;
      if (this.calculatedTime < 0) {
        this.hidden = true;
        return false;
      }
      this.ts = Date.now();
      this.$forceUpdate();
      return true;
    },
  },
  created() {
    this.ts = this.timestamp;
    this.calculatedTime = this.time;
    const interval = setInterval(() => {
      const result = this.update();
      if (!result) {
        clearInterval(interval);
      }
    }, 1000);
  },
  mounted() {
    this.update();
  },
})
export default class SongsListItem extends Vue {
  formatTime(calculatedTime: number) {
    const timeSeconds = Math.floor(calculatedTime / 1000);

    const seconds = timeSeconds % 60;
    const minutes = Math.floor(timeSeconds / 60);

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
  name!: string;
  time!: number;
  timestamp!: number;
  ts?: number;
  calculatedTime?: number;
  hidden = false;
  isCurrent!: boolean;
}
