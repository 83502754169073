import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SongsListItem = _resolveComponent("SongsListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SongsListItem, {
      isCurrent: true,
      name: _ctx.currentSong?.songName,
      time: _ctx.currentSong?.timeTillSong,
      timestamp: _ctx.timestamp
    }, null, 8, ["name", "time", "timestamp"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.songs, (s) => {
      return (_openBlock(), _createBlock(_component_SongsListItem, {
        key: s,
        isCurrent: false,
        name: s.songName,
        time: s.timeTillSong,
        timestamp: _ctx.timestamp
      }, null, 8, ["name", "time", "timestamp"]))
    }), 128))
  ]))
}