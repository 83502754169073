import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "trackName" }
const _hoisted_2 = {
  key: 0,
  class: "trackTime"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ outter: true, hidden: _ctx.hidden, isCurrent: _ctx.isCurrent })
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.name), 1),
    (!_ctx.isCurrent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " (" + _toDisplayString(_ctx.formatTime(_ctx.calculatedTime || 0)) + ") ", 1))
      : _createCommentVNode("", true)
  ], 2))
}